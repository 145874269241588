import { Singleton } from 'typescript-ioc/es5';
import { Operation, OpResult } from './models/operation';
import { Status } from './constants';
import { RefreshStatus } from '../refresh/refreshStatus';

@Singleton
export class MessageCreator {
    public invalidConfig(op?: Operation): OpResult {
        if (!op) {
            return {
                opId: 'local',
                status: Status.invalidConfig,
                errorMessage: 'invalid configuration'
            };
        }
        return {
            opId: op.opId,
            status: Status.invalidConfig,
            errorMessage: 'invalid configuration'
        };
    }

    public ssoRetrieveError(op?: Operation): OpResult {
        if (!op) {
            return {
                opId: 'local',
                status: Status.ssoRetrieveError,
                errorMessage: 'sso return error'
            };
        }
        return {
            opId: op.opId,
            status: Status.ssoRetrieveError,
            errorMessage: 'sso return error'
        };
    }

    public noLoginData(op?: Operation): OpResult {
        if (!op) {
            return {
                opId: 'local',
                status: Status.noLoginData,
                errorMessage: 'no login data'
            };
        }
        return {
            opId: op.opId,
            status: Status.noLoginData,
            errorMessage: 'no login data'
        };
    }

    public genericError(op?: Operation): OpResult {
        if (!op) {
            return {
                opId: 'local',
                status: Status.error,
                errorMessage: 'generic error'
            };
        }
        return {
            opId: op.opId,
            status: Status.error,
            errorMessage: 'generic error'
        };
    }

    public getErrorByStatus(status: RefreshStatus, op?: Operation): OpResult {
        switch (status) {
            case RefreshStatus.noLoginData:
                return this.noLoginData(op);
            case RefreshStatus.invalidConfig:
                return this.invalidConfig(op);
            case RefreshStatus.ssoError:
                return this.ssoRetrieveError(op);
            default:
                return this.genericError(op);
        }
    }
}
