import { Observable, Subject } from 'rxjs';
import { Constants } from './constants';
import { ConfigService } from './configService';
import { Inject, Singleton } from 'typescript-ioc/es5';
import { Operation, OpResult } from './models/operation';

@Singleton
export class Communication {
    @Inject
    private configService: ConfigService;
    private iframe: HTMLIFrameElement;
    private incomingMessage: Subject<any>;

    public static generateUniqueId(seed: string = ''): string {
        let initialVal = seed;
        initialVal += (Math.random() * 10000).toString().replace(/./g, '').substring(0, 5);
        return initialVal;
    }

    constructor() {
        this.incomingMessage = new Subject<any>();
    }

    public setupCommunicationEvent(): void {
        window.removeEventListener('message', (msg) => this.receivePostMessage(msg), true);
        window.addEventListener('message', (msg) => this.receivePostMessage(msg), true);
    }

    public sendMessageToParent(message: OpResult, to: string): boolean {
        if (window.parent) {
            window.parent.postMessage(JSON.stringify(message), to);
            return true;
        }
        return false;
    }

    public sendMessageToChild(message: Operation, to?: string): boolean {
        if (this.iframe && this.iframe.contentWindow) {
            this.iframe.contentWindow.postMessage(JSON.stringify(message), to || this.configService.config.sender);
            return true;
        }
        return false;
    }

    public receivePostMessage = (event: Event): void => {
        const msgEvent = event as MessageEvent;
        if (msgEvent.origin !== this.configService.config.sender) {
            return;
        }
        if (msgEvent.data) {
            try {
                this.incomingMessage.next({
                    source: msgEvent.source,
                    origin: msgEvent.origin,
                    data: JSON.parse(msgEvent.data)
                });
            } catch (e) {}
        }
    };

    public getIncomigMessages(): Observable<any> {
        return this.incomingMessage.asObservable();
    }

    public setupCommunicationIframe(): void {
        document.body.appendChild(this.getCommunicationIframe());
    }

    private getCommunicationIframe(thirdParty = false): HTMLIFrameElement {
        const iframe: HTMLIFrameElement = document.createElement('iframe');
        iframe.src =
            Constants.baseUrls.find((f) => f.key === this.configService.config.environment).value +
            '/sdk.html?env=' +
            this.configService.config.environment +
            '&appId=' +
            this.configService.config.appId +
            '&genomeId=' +
            this.configService.config.genomeId +
            '&thirdParty' +
            thirdParty +
            '&lang=' +
            this.configService.config.lang +
            '&nextUrl=' +
            encodeURIComponent(this.configService.config.nextUrl) +
            '&host=' +
            encodeURIComponent(window.location.protocol + '//' + window.location.host);
        iframe.height = '0';
        iframe.width = '0';
        iframe.id = 'sdk_comm';
        iframe.setAttribute('style', 'display: none');
        this.iframe = iframe;
        return this.iframe;
    }
}
