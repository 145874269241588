import { ConfigService } from './configService';
import { Inject, Singleton } from 'typescript-ioc/es5';

@Singleton
export class LocalStorage {
    @Inject
    private configService: ConfigService;
    private readonly defaultStorageOptions: StorageOptions;

    constructor() {
        this.defaultStorageOptions = {
            unit: 'min',
            unix: false
        };
    }

    public getItem<T>(key: string, message?: string): T {
        try {
            const item = JSON.parse(localStorage.getItem((this.configService.config.environment + key).trim())) as T;
            // if (Date.parse((item as any).expirationDate) <= (new Date()).getTime()) {
            if (((item as any).expirationDate as number) <= new Date().getTime()) {
                localStorage.removeItem(this.configService.config.environment + key);
                return null;
            }
            return item;
        } catch (e) {
            return null;
        }
    }

    public setItem<T>(key: string, item: T, expirationTimeSpan: number = 175, options?: StorageOptions): void {
        try {
            options = {
                ...this.defaultStorageOptions,
                ...(options || {})
            };

            let expiry: number;
            if (!options.unix) {
                const date = new Date();
                const conversionRate = options.unit === 'min' ? 60 * 1_000 : 1;
                date.setTime(date.getTime() + expirationTimeSpan * conversionRate);
                expiry = date.getTime();
            } else {
                expiry = expirationTimeSpan;
            }

            (item as any).expirationDate = expiry;
            localStorage.setItem(this.configService.config.environment + key, JSON.stringify(item));
        } catch {}
    }

    public removeItem(key: string): void {
        localStorage.removeItem(this.configService.config.environment + key);
    }

    public getItemWithoutExpiration<T>(key: string, message?: string): T {
        try {
            const item = JSON.parse(localStorage.getItem((this.configService.config.environment + key).trim())) as T;
            return item;
        } catch (e) {
            return null;
        }
    }

    public setItemWithoutExpiration<T>(key: string, item: T): void {
        try {
            localStorage.setItem(this.configService.config.environment + key, JSON.stringify(item));
        } catch {}
    }
}

export interface StorageOptions {
    unit?: 'min' | 'ms';
    unix?: boolean;
}
