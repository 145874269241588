export class ConfigModel {
    appId: string;
    environment: string;
    genomeId: string;
    lang: string;
    nextUrl: string;
    thirdPartyCookieSupport: boolean;
    localLoginExpirationTime: number;
    sender: string;
    constructor(init?: Partial<ConfigModel>) {
        Object.assign(this, init);
    }
}
