import { LoginData } from "../private/loginData";
import { Constants } from "./constants";

export class Utilities {
    public static removeURLParameter = (url: string, ...params: string[]): string => {
        let urlparts = url.split('?');

        if (urlparts.length >= 2) {
            const prefixes = params.map((x) => encodeURIComponent(x) + '=');
            let pars = urlparts[1].split(/[&;]/g);

            // Reverse iteration as may be destructive
            for (let i = pars.length - 1; i >= 0; --i) {
                if (prefixes.some((prefix) => pars[i].lastIndexOf(prefix) !== -1)) {
                    pars.splice(i, 1);
                }
            }

            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }

        return url;
    };

    public static getQsParams(url?: string): Record<string, string> {
        if (!url) {
            url = window.location.href;
        }

        const recvParams: Record<string, string> = {};
        const search = new URL(url).search.substring(1);
        const params = decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"');
        const qsParams = JSON.parse(`{"${params}"}`);

        for (const prop in qsParams) {
            recvParams[prop] = decodeURIComponent(qsParams[prop]);
        }

        return recvParams;
    }

    public static getQsParam(paramName: string, url?: string): string {
        if (!url) {
            url = window.location.href;
        }
        paramName = paramName.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + paramName + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    public static getQsParamWithAllChars(paramName: string, url?: string) {
        const rez = Utilities.getQsParam(paramName, url);
        if (rez == null || rez === '') {
            return rez;
        }
        return rez.replace(/ /g, '+');
    }

    public static thirdPartyEnabled = (): boolean => {
        try {
            // Check if the PrivateSDK can access WebAuth's Storage
            localStorage.setItem('chkThird', JSON.stringify(new Date().getTime()));
            const val = localStorage.getItem('checkForBadBrowser');
            if (!val && Utilities.isIOSBrowser()) {
                return false;
            }
            return true;
        } catch {
            return false;
        }
    };

    // iOS browsers run on the same engine and behave the same internally
    public static isIOSBrowser = (): boolean => {
        return navigator.vendor && navigator.vendor.indexOf('Apple') > -1;
    };

    public static getObjectWithLowercaseKeys = (obj: any) => {
        var keys = Object.keys(obj);
        var n = keys.length;
        var newobj: any = {};

        while (n--) {
            var key = keys[n];
            newobj[key.toLowerCase()] = obj[key];
        }

        return newobj;
    };

    public static getLoginDataMinsBeforeExpiration = (loginData: LoginData): number => {
        //Return default in case of no loginData info
        if (!loginData || !loginData.expiration) {
            return 143;
        }
        const ticketExpiration = new Date(loginData.expiration);
        const now = new Date();

        const diffInMinutes = (ticketExpiration.getTime() - now.getTime()) / 1000 / 60; //milisec / sec / mins

        //ubi ticket is valid for 180min, if difference < ~36 return 0 so the ticket will be renewed
        const renewInMins = Math.floor(diffInMinutes - Constants.maxMinLeftBeforeLoginDataRenewal);
        return renewInMins < 0 ? 0 : renewInMins;
    };
}
