import { FeatureFlag } from './@types/featureFlag';

export class Constants {
    public static Environments = ['DEV', 'TEST', 'UAT', 'PROD', 'CERT2'];

    public static baseUrls = [
        { key: 'DEV', value: 'https://localhost.ubisoft.com' },
        { key: 'UAT', value: 'https://uat-connect.ubisoft.com' },
        { key: 'CERT2', value: 'https://cert2-connect.ubisoft.com' },
        { key: 'PROD', value: 'https://connect.ubisoft.com' },
        { key: 'TEST', value: 'https://dev-connect.ubisoft.com' }
    ];
    public static baseApiUrls = [
        { key: 'DEV', value: 'https://localhost.ubisoft.com:50000' },
        { key: 'UAT', value: 'https://uat-connect.ubisoft.com' },
        { key: 'CERT2', value: 'https://cert2-connect.ubisoft.com' },
        { key: 'PROD', value: 'https://connect.ubisoft.com' },
        { key: 'TEST', value: 'https://dev-connect.ubisoft.com' }
    ];

    public static webAuthServiceBaseUrls = [
        { key: 'DEV', value: 'https://dev-connect.ubisoft.com/v2/webauth' }, //https://localhost.ubisoft.com:7176/v2/webauth
        { key: 'UAT', value: 'https://uat-connect.ubisoft.com/v2/webauth' },
        { key: 'CERT2', value: 'https://cert2-connect.ubisoft.com/v2/webauth' },
        { key: 'PROD', value: 'https://connect.ubisoft.com/v2/webauth' },
        { key: 'TEST', value: 'https://dev-connect.ubisoft.com/v2/webauth' }
    ];

    public static crossChannelAuthServiceBaseUrls = [
        { key: 'DEV', value: 'https://dev-connect.ubisoft.com/v2/crosschannelauth' }, //https://localhost:7126/v2/crosschannelauth
        { key: 'UAT', value: 'https://uat-connect.ubisoft.com/v2/crosschannelauth' },
        { key: 'CERT2', value: 'https://cert2-connect.ubisoft.com/v2/crosschannelauth' },
        { key: 'PROD', value: 'https://connect.ubisoft.com/v2/crosschannelauth' },
        { key: 'TEST', value: 'https://dev-connect.ubisoft.com/v2/crosschannelauth' }
    ];

    public static ubiservicesBaseUrls = [
        { key: 'DEV', value: 'https://uat-public-ubiservices.ubi.com' },
        { key: 'UAT', value: 'https://uat-public-ubiservices.ubi.com' },
        { key: 'CERT2', value: 'https://cert2-public-ubiservices.ubi.com' },
        { key: 'PROD', value: 'https://public-ubiservices.ubi.com' },
        { key: 'TEST', value: 'https://uat-public-ubiservices.ubi.com' }
    ];

    public static cookieDomains = [
        { key: 'DEV', value: 'ubisoft.com' },
        { key: 'UAT', value: 'ubisoft.com' },
        { key: 'CERT2', value: 'ubisoft.com' },
        { key: 'PROD', value: 'ubisoft.com' }
    ];

    public static readonly PRIMARY_DOMAIN = 'ubisoft.com';

    public static defaultAppId = '412802ED-8163-4642-A931-8299F209FECB';
    public static defaultNextUrl = 'https://ubisoft.com';
    public static defaultLang = 'en-US';

    public static readonly expiration29Days = 20160;
    public static readonly expiration6Months = 262974;
    public static readonly logoutKey = 'lgdOut';
    public static readonly loginDataKey = 'loginData';
    public static readonly lastProfileKey = 'lastProfile';
    public static readonly rememberKey = 'rememberMe';
    public static readonly rememberDeviceKey = 'rememberDevice';
    public static readonly localErrorKey = 'errorRefreshData';
    public static readonly loginDataExpiration = 177;
    public static readonly maxMinLeftBeforeLoginDataRenewal = 36;
    public static readonly mutexKey = 'lockRequestsKey';
    public static readonly validDomainsKey = 'validDomains';

    public static readonly LogOffUserKey = 'logOffUser';
    public static readonly RefreshStatusKey = 'refreshStatus';

    public static readonly SsoIdKey = 'connectSsoId';
    public static readonly SsoStatusKey = 'SSOStatus';
    public static readonly SsoCallBackStatusKey = 'ssoCallBackStatusKey';

    public static readonly GetTicketThrottleKey = 'getTicketThrottle';
    public static readonly GetTicketThorttleSettings = {
        maxTries: 15,
        maxTriesIntervalInMinutes: 1,
        throttleTimeInMinutes: 10
    };

    public static featureFlags: { [key: string]: FeatureFlag } = {
        storagePartitioning: {
            PROD: {
                accessType: 'restricted',
                exclude: [
                    {
                        // R6FIX
                        appId: '685a3038-2b04-47ee-9c5a-6403381a46aa',
                        genomeId: 'e839c7e5-fd3e-45e5-88a8-cdcb5ad59fbd'
                    }
                ]
            },
            CERT2: {
                accessType: 'allowed'
            },
            UAT: {
                accessType: 'allowed'
            },
            TEST: {
                accessType: 'allowed'
            },
            DEV: {
                accessType: 'allowed'
            }
        },
        sendBackSsoIdOnLogin: {
            PROD: {
                accessType: 'restricted',
                include: [
                    {
                        appId: '7b530ae1-86e2-4753-921a-267d4b0e498d' // Epic Games
                    }
                ]
            },
            CERT2: {
                accessType: 'restricted',
                include: [
                    {
                        appId: '7b530ae1-86e2-4753-921a-267d4b0e498d' // Epic Games
                    }
                ]
            },
            UAT: {
                accessType: 'restricted',
                include: [
                    {
                        appId: '7b530ae1-86e2-4753-921a-267d4b0e498d' // Epic Games
                    }
                ]
            },
            TEST: {
                accessType: 'restricted',
                include: [
                    {
                        appId: '7b530ae1-86e2-4753-921a-267d4b0e498d' // Epic Games
                    }
                ]
            },
            DEV: {
                accessType: 'restricted',
                include: [
                    {
                        appId: '7b530ae1-86e2-4753-921a-267d4b0e498d' // Epic Games
                    }
                ]
            }
        }
    };
}

export enum FeatureFlags {
    storagePartitioning = 'storagePartitioning',
    sendBackSsoIdOnLogin = 'sendBackSsoIdOnLogin'
}

export enum Status {
    ready = 'ready',
    error = 'error',
    ok = 'ok',
    throttled = 'throttled',
    invalidDomain = 'invalidDomain',
    ageGateFailed = 'agaGateFailed',
    twoFaFailed = '2faFailed',
    invalidConfig = 'invalidConfig',
    missingTicket = 'missingTicket',
    missingSessionId = 'missingSessionId',
    missingUserId = 'missingUserId',
    thirdPartyCookiesDisabled = 'thirdPartyCookiesDisabled',
    ssoRetrieveError = 'ssoRetrieveError',
    ssoJustLoggedOut = 'ssoJustLoggedOut',
    ssoRetrieveApiError = 'ssoRetrieveApiError',
    noLoginData = 'noLoginData'
}

export enum OpType {
    GetTicket = 'GetTicket',
    SetTicket = 'SetTicket',
    GetProfiles = 'GetProfiles',
    GetUserStatus = 'GetUserStatus',
    TriggerEmailActivation = 'TriggerEmailActivation',
    CheckThirdPartyStatus = 'CheckThirdPartyStatus',
    Logout = 'Logout',
    GetThirdPartyStatus = 'GetThirdPartyStatus',
    GetLSData = 'GetLSData',
    GetUsername = 'GetUsername'
}

export interface KeyValuePairString {
    key: string;
    value: string;
}
