import { Inject, Singleton } from 'typescript-ioc/es5';
import { ConfigService } from './configService';
import { Constants } from './constants';
import { FeatureFlag, FeatureFlagCondition, FeatureFlagSettings } from './@types/featureFlag';

@Singleton
export class FeatureFlagService {
    @Inject
    private configService: ConfigService;

    public isActive(feature: keyof typeof Constants.featureFlags): boolean {
        // For an invalid environment entry allow by default
        if (-1 === Constants.Environments.indexOf(this.configService.config.environment)) {
            return true;
        }

        // For a missing feature entry allow by default
        if (!(feature in Constants.featureFlags)) {
            return true;
        }

        // By default allow the feature to be active if no configuration is present
        if (!Constants.featureFlags || !(this.configService.config.environment in Constants.featureFlags[feature])) {
            return true;
        }

        const flagEnvironment: keyof FeatureFlag = this.configService.config.environment as any;
        const flagSettings: FeatureFlagSettings = Constants.featureFlags[feature][flagEnvironment];

        switch (flagSettings.accessType) {
            case 'allowed':
                return true;
            case 'blocked':
                return false;
            case 'restricted':
                return !this.isRestricted(flagSettings);
            default:
                return true;
        }
    }

    private isRestricted(flagSettings: FeatureFlagSettings): boolean {
        // By default, if only `include` is specified the context needs to be marked explicitly.
        // Otherwise it is excluded, in other words only a subset may benefit from a feature.
        // Exclude Include - Behavior
        // true    true    - allow by default
        // true    false   - allow by default
        // false   true    - block by default
        // false   false   - allow by default
        const default_handling = !!(!flagSettings.exclude && flagSettings.include);

        // Check if the current context is handled by the rules in the config
        const isRuleMatching = (rule: FeatureFlagCondition) => {
            const sameAppId = !rule.appId || rule.appId === this.configService.config.appId;
            const sameGenomeId = !rule.genomeId || rule.genomeId === this.configService.config.genomeId;
            return sameAppId && sameGenomeId;
        };

        // Check if the current context is handled explicitly.
        let blocked: boolean = false;
        let allowed: boolean = false;
        if (flagSettings.exclude && flagSettings.exclude.length !== 0) {
            blocked = flagSettings.exclude.some(isRuleMatching);
        }
        if (flagSettings.include && flagSettings.include.length !== 0) {
            allowed = flagSettings.include.some(isRuleMatching);
        }

        // Fallback to the default handling if no explicit rule was found.
        if (!blocked && !allowed) {
            return default_handling;
        }

        // Inclusion is prioritized over exclusion
        if (blocked && allowed) {
            return false;
        }

        if (blocked) {
            return true;
        }

        return false;
    }
}
