import { Observable, Subject, of, throwError } from 'rxjs';
import { BaseConnectSdk } from '../common/baseConnectSdk';
import { Communication } from '../common/communication';
import { Inject } from 'typescript-ioc/es5';
import { OpResult, PayloadData } from '../common/models/operation';
import { OpType, Constants, Status, FeatureFlags } from '../common/constants';
import { UrlHandler } from '../refresh/urlHandler';
import { Utilities } from '../common/utilities';
import { SsoService } from '../refresh/ssoService';
import { RefreshStatus } from '../refresh/refreshStatus';
import { MessageCreator } from '../common/messageCreator';
import { LoginData } from '../private/loginData';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { FeatureFlagService } from '../common/featureFlagService';
import { DebugService } from '../common/debugService';

export default class ConnectSdk extends BaseConnectSdk {
    @Inject
    private featureFlagService: FeatureFlagService;
    @Inject
    private communication: Communication;
    @Inject
    private urlHandler: UrlHandler;
    @Inject
    private ssoService: SsoService;
    @Inject
    private messageCreator: MessageCreator;
    @Inject
    private debugService: DebugService;
    private queue: { [id: string]: Subject<OpResult> };
    public ready = new Subject<ReadyStatus>();
    private sdkStatus: ReadyStatus;
    private sharedAuthUrl?: string | undefined;

    constructor(
        env: string,
        appId: string,
        genomeId?: string,
        lang?: string,
        nextUrl?: string,
        thirdPartyCookieSupport?: boolean,
        localLoginExpirationTime?: number,
        callbackInit?: boolean
    ) {
        super(env, appId, genomeId, lang, nextUrl, thirdPartyCookieSupport, localLoginExpirationTime);
        this.configService.config.sender = Constants.baseUrls.find(
            (f) => f.key === this.configService.config.environment
        ).value;
        this.queue = {};
        this.sdkStatus = <ReadyStatus>{ sdkReady: false, thirdPartyCookiesEnabled: true };

        if (!callbackInit) {
            this.communication.setupCommunicationEvent();
            this.communication.getIncomigMessages().subscribe((message) => this.handleMessages(message));
            this.communication.setupCommunicationIframe();
        }

        this.debugService.sendDebugLogs('', 'initSdk', 'sdk').subscribe();
    }

    private handleMessages(message: MessageEvent): void {
        if (!message || !message.data) {
            return;
        }
        if (message.data.webAuthLaunched === true) {
            this.ls.setItem(Constants.SsoStatusKey, { id: RetrieveStatus.Initial });
        }
        if (message.data.operation === 'redirect-send-url' && this.sdkStatus.sdkReady) {
            (message as any).source.postMessage({ ...message.data }, this.configService.config.sender);
            this.sharedAuthUrl = message.data.data;
        }
        if (message.data.operation === 'redirect-execute' && this.sharedAuthUrl && this.sdkStatus.sdkReady) {
            window.location.href = this.sharedAuthUrl;
            return;
        }
        if (message.data.operation === 'iurl' && this.sdkStatus.sdkReady) {
            (message as any).source.postMessage(
                { ...message.data, data: window.location.href },
                this.configService.config.sender
            );
        }
        if (message.data.operation === 'domain' && this.sdkStatus.sdkReady) {
            (message as any).source.postMessage(
                { ...message.data, data: `isPrimaryDomain: ${this.sdkStatus.isPrimaryDomain}` },
                this.configService.config.sender
            );
        }
        if (message.data.redirectUrl && message.data.redirectUrl != '') {
            window.location.href = message.data.redirectUrl;
        }
        const transferData = message.data as OpResult;
        const sdkReadyEvent = this.checkAndSetIfItsReadyEvent(transferData);
        if (sdkReadyEvent.sdkReady) {
            //Set the sdkStatus so the correct getTicket flow can be triggered
            this.sdkStatus = sdkReadyEvent;
            //The ready status will be set after checking if the ticket needs renewal, so the getTicket doesn't get twice (by renewal process and integrator)
            this.handleTicketRenewalProcess(true);
        }
        if (this.queue[transferData.opId]) {
            //check for messages in queue and pass the response to the subscribers
            this.queue[transferData.opId].next(transferData);
        }
    }

    private setSdkReadyStatus() {
        this.ready.next(this.sdkStatus);
        this.ready.complete();
    }

    //The method is kept for legacy reasons. This was used during the TCP Disabled Flow which is now not needed after implementing the fix for storage partitioning
    //Reference for this method: https://confluence.ubisoft.com/display/webservices/5.4+-+Ubisoft+WebAuth+-+Technical+Documentation+-+Third+Party+Cookies+Disabled
    public getTicketFromSsoId(callback: (result: any) => void): void {
        this.getTicket().subscribe((result: GetTicketOpResult) => callback(result));
    }

    public refreshWithThirdPartyDisabled({
        nextUrl,
        sendBackQParams
    }: { nextUrl?: string; sendBackQParams?: string } = {}): void {
        if (!nextUrl) {
            nextUrl = this.configService.config.nextUrl;
        }

        let url =
            Constants.baseUrls.find((f) => f.key === this.configService.config.environment).value + '/refresh.html';

        const fullUrl = this.urlHandler.appendParams(url, [
            { key: 'env', value: this.configService.config.environment },
            { key: 'appId', value: this.configService.config.appId },
            { key: 'genomeId', value: this.configService.config.genomeId },
            { key: 'nextUrl', value: encodeURIComponent(nextUrl) },
            { key: 'sendBackQParams', value: sendBackQParams }
        ]);

        window.top.location.href = fullUrl;
    }

    public checkAndSetIfItsReadyEvent(transferData: OpResult): ReadyStatus {
        const isPrimaryDomain = transferData && transferData.payload ? transferData.payload.isPrimaryDomain : false;
        switch (true) {
            case transferData.opId !== 'everythingSet':
                return { sdkReady: false, thirdPartyCookiesEnabled: false, message: '', isPrimaryDomain: false };
            case transferData.status === Status.ready:
                return {
                    sdkReady: true,
                    thirdPartyCookiesEnabled: true,
                    message: '',
                    isPrimaryDomain: isPrimaryDomain
                };
            case transferData.status === Status.thirdPartyCookiesDisabled:
                return {
                    sdkReady: true,
                    thirdPartyCookiesEnabled: false,
                    message: '',
                    isPrimaryDomain: isPrimaryDomain
                };
            default:
                return {
                    sdkReady: false,
                    thirdPartyCookiesEnabled: false,
                    message: '',
                    isPrimaryDomain: isPrimaryDomain
                };
        }
    }

    public logout(ticket: string, sessionId: string): Observable<OpResult> {
        this.ls.removeItem(Constants.loginDataKey);
        if (!this.sdkStatus.thirdPartyCookiesEnabled) {
            return this.logoutWithoutThirdParties();
        }

        if (this.featureFlagService.isActive(FeatureFlags.storagePartitioning)) {
            if (!this.sdkStatus.isPrimaryDomain) {
                return this.logoutWithThirdParties(ticket, sessionId).pipe(tap(() => this.logOffFromPrimaryDomain()));
            }
        }

        return this.logoutWithThirdParties(ticket, sessionId);
    }

    private logoutWithoutThirdParties(): Observable<OpResult> {
        this.ls.removeItem(Constants.localErrorKey);
        this.ls.removeItem(Constants.SsoCallBackStatusKey);

        if (this.configService.config.thirdPartyCookieSupport) {
            this.logOffWithThirdPartyDisabled();
        } else if (this.featureFlagService.isActive(FeatureFlags.storagePartitioning)) {
            this.logOffFromPrimaryDomain();
        }

        return of({
            status: Status.ok,
            opId: 'local'
        } as OpResult);
    }

    private logOffFromPrimaryDomain(): void {
        this.ls.removeItem(Constants.loginDataKey);
        this.ls.setItem(Constants.SsoStatusKey, { id: RetrieveStatus.LoggedOut });
        const nextUrl = Utilities.removeURLParameter(
            window.location.href,
            Constants.SsoIdKey,
            Constants.RefreshStatusKey,
            Constants.logoutKey
        );
        this.logOffWithThirdPartyDisabled({ nextUrl, sendBackQParams: 'false' });
    }

    public logOffWithThirdPartyDisabled({
        nextUrl,
        sendBackQParams
    }: { nextUrl?: string; sendBackQParams?: string } = {}): void {
        if (!nextUrl) {
            nextUrl = this.configService.config.nextUrl;
        }

        const url =
            Constants.baseUrls.find((f) => f.key === this.configService.config.environment).value + '/refresh.html';

        const fullUrl = this.urlHandler.appendParams(url, [
            { key: 'env', value: this.configService.config.environment },
            { key: 'appId', value: this.configService.config.appId },
            { key: 'genomeId', value: this.configService.config.genomeId },
            { key: 'nextUrl', value: encodeURIComponent(nextUrl) },
            { key: 'sendBackQParams', value: sendBackQParams },
            { key: Constants.LogOffUserKey, value: 'true' }
        ]);

        window.top.location.href = fullUrl;
    }

    private logoutWithThirdParties(ticket: string, sessionId: string): Observable<OpResult> {
        const id = Communication.generateUniqueId(OpType.Logout);
        this.queue[id] = new Subject<OpResult>();
        this.communication.sendMessageToChild(
            {
                opId: id,
                type: OpType.Logout,
                thirdPartySupport: this.configService.config.thirdPartyCookieSupport,
                payload: { ticket: ticket, sessionId: sessionId }
            },
            this.configService.config.sender
        );
        return this.queue[id];
    }

    public triggerEmailActivation(ticket: string, sessionId: string): Observable<OpResult> {
        const id = Communication.generateUniqueId(OpType.TriggerEmailActivation);
        this.queue[id] = new Subject<OpResult>();
        this.communication.sendMessageToChild(
            {
                opId: id,
                type: OpType.TriggerEmailActivation,
                thirdPartySupport: this.configService.config.thirdPartyCookieSupport,
                payload: { ticket: ticket, sessionId: sessionId }
            },
            this.configService.config.sender
        );
        return this.queue[id];
    }

    private getTicketWithThirdParties(): Observable<OpResult> {
        const id = Communication.generateUniqueId(OpType.GetTicket);
        this.queue[id] = new Subject<OpResult>();
        this.communication.sendMessageToChild(
            {
                opId: id,
                thirdPartySupport: this.configService.config.thirdPartyCookieSupport,
                type: OpType.GetTicket
            },
            this.configService.config.sender
        );
        return this.queue[id];
    }

    private getCookiesDisabledNotification(): Observable<OpResult> {
        return of({
            opId: 'local',
            errorMessage: 'thirdPartyCookies are disabled, use the sdk option to be able to retrieve the data',
            status: Status.thirdPartyCookiesDisabled
        } as OpResult);
    }

    private getTicketWithoutThirdParties(): Observable<OpResult> {
        const localData = this.ls.getItem<any>(Constants.loginDataKey);
        if (localData) {
            return of(localData);
        }
        const localError = this.ls.getItem<any>(Constants.localErrorKey);
        this.ls.removeItem(Constants.SsoCallBackStatusKey);
        if (localError) {
            return of(localError);
        }
        return of(this.messageCreator.genericError());
    }

    private shouldThrottle(): boolean {
        //Get the throttle state and initialize it with one count if it doesn't exist
        const throttle = this.ls.getItem<ThrottleState>(Constants.GetTicketThrottleKey);
        if (!throttle) {
            console.log('[WebAuthSdk]: Creating throttle entry');
            this.ls.setItem(
                Constants.GetTicketThrottleKey,
                {
                    count: 1,
                    throttledUntil: 0
                } as ThrottleState,
                Constants.GetTicketThorttleSettings.maxTriesIntervalInMinutes
            );
            return false;
        }
        const currentTime = new Date().getTime();

        //throttledUntil === 0 -> the user is not yet throttled
        if (throttle.throttledUntil === 0) {
            //If the count did not exceed the limit, increase the count
            throttle.count++;
            if (throttle.count <= Constants.GetTicketThorttleSettings.maxTries) {
                console.log(`[WebAuthSdk]: Increase throttle count to ${throttle.count}`);
                const expirationInMs = (throttle as any).expirationDate - currentTime;
                this.ls.setItem(Constants.GetTicketThrottleKey, throttle, expirationInMs, {
                    unit: 'ms'
                });
                return false;
            }

            //If the count exceeded the limit, reset the count and set the throttle time
            throttle.throttledUntil =
                currentTime + Constants.GetTicketThorttleSettings.throttleTimeInMinutes * 60 * 1000;
            console.log(`[WebAuthSdk]: Throttle enabled on method until ${throttle.throttledUntil}`);
            this.ls.setItem(Constants.GetTicketThrottleKey, {
                count: 0,
                throttledUntil: throttle.throttledUntil
            } as ThrottleState);
            this.debugService.sendDebugLogs('', 'getTicketThrottle', 'sdk').subscribe();
            return true;
        }

        //If the throttle time is set and it's greater then current time, throttle the user
        if (throttle.throttledUntil > currentTime) {
            console.log('[WebAuthSdk]: Throttle is active, method not called');
            return true;
        }

        //If the throttle time it's less then current time, reset the state and unblock the user
        console.log('[WebAuthSdk]: Throttle expired, removing the lock');
        this.ls.setItem(
            Constants.GetTicketThrottleKey,
            {
                count: 1,
                throttledUntil: 0
            } as ThrottleState,
            Constants.GetTicketThorttleSettings.maxTriesIntervalInMinutes
        );
        return false;
    }

    public getTicket(): Observable<OpResult> {
        // if(this.shouldThrottle()){
        //     return of({
        //         opId: 'local',
        //         errorMessage: 'You were throttled due to multiple getTicket requests in a short period of time',
        //         status: Status.throttled
        //     } as OpResult);
        // }
        const tcpDisabledFlow =
            this.configService.config.thirdPartyCookieSupport && !this.sdkStatus.thirdPartyCookiesEnabled;
        switch (true) {
            case !this.configService.config.thirdPartyCookieSupport && !this.sdkStatus.thirdPartyCookiesEnabled:
                return this.getCookiesDisabledNotification();
            case (!this.sdkStatus.isPrimaryDomain || tcpDisabledFlow) &&
                this.featureFlagService.isActive(FeatureFlags.storagePartitioning):
                return this.getTicketFromPrimaryDomain();
            default:
                return this.getTicketWithThirdParties();
        }
    }

    private getTicketFromPrimaryDomain(): Observable<OpResult> {
        const status = this.ls.getItem<any>(Constants.SsoStatusKey);
        if (!status || status.id === RetrieveStatus.Initial || status.id === RetrieveStatus.Stored) {
            return this.getTicketByParamOrCache();
        } else if (status.id === RetrieveStatus.Fetch) {
            return this.getTicketBySsoRefresh();
        } else if (status.id === RetrieveStatus.LoggedOut) {
            this.ls.setItem(Constants.SsoStatusKey, { id: RetrieveStatus.NotFound }, 5);
            return of({
                opId: 'local',
                status: Status.ssoJustLoggedOut,
                errorMessage: 'Just logged out, authenticate again to obtain a ticket!'
            } as OpResult);
        } else {
            return of({
                opId: 'local',
                status: Status.ssoRetrieveError,
                errorMessage: 'Could not obtain a session!'
            } as OpResult);
        }
    }

    private getTicketByParamOrCache(): Observable<OpResult> {
        if (
            Utilities.getQsParam(Constants.SsoIdKey) &&
            this.featureFlagService.isActive(FeatureFlags.sendBackSsoIdOnLogin)
        ) {
            return this.getTicketBySsoRefresh();
        }

        const existingLoginData = this.ls.getItem<GetTicketOpResult | LoginData>(Constants.loginDataKey);
        if (existingLoginData) {
            //This is a unique case that only occurs on the connect.ubisoft.com/logged-in.html page
            //This is needed since both public and private sdk are running on connect.ubisoft.com domain and there is mixed data between webauth and sdk
            let loginDataPayload = (existingLoginData as GetTicketOpResult).payload;
            if (!loginDataPayload) {
                const tempLoginData = existingLoginData as LoginData;
                loginDataPayload = {
                    ticket: tempLoginData.ticket,
                    userId: tempLoginData.userId,
                    sessionId: tempLoginData.sessionId,
                    expiration: tempLoginData.expiration
                } as LoginData;
            }
            if (Utilities.getLoginDataMinsBeforeExpiration(loginDataPayload) > 0) {
                return of({ status: Status.ok, payload: loginDataPayload, opId: 'local' } as GetTicketOpResult);
            }
        }

        return this.getTicketWithThirdParties().pipe(
            switchMap((result: OpResult) => {
                if (result.status === Status.ok) {
                    // Also store the login data under the current origin
                    this.ls.setItem(Constants.loginDataKey, result, this.configService.config.localLoginExpirationTime);
                    return of(result);
                }

                // Navigate to ubisoft.com and fetch it from there
                this.ls.setItem(Constants.SsoStatusKey, { id: RetrieveStatus.Fetch });
                const nextUrl = Utilities.removeURLParameter(
                    window.location.href,
                    Constants.SsoIdKey,
                    Constants.RefreshStatusKey,
                    Constants.logoutKey
                );
                this.refreshWithThirdPartyDisabled({ nextUrl, sendBackQParams: 'false' });
            })
        );
    }

    private getTicketBySsoRefresh(): Observable<OpResult> {
        const ssoId = decodeURIComponent(Utilities.getQsParamWithAllChars(Constants.SsoIdKey));

        if (ssoId === 'null') {
            this.ls.setItem(Constants.SsoStatusKey, { id: RetrieveStatus.NotFound }, 5);
            return of({
                opId: 'local',
                status: Status.ssoRetrieveError,
                errorMessage: 'Could not obtain a session!'
            } as OpResult);
        }
        //Remove 'connectSsoId' key from query parameters since the ssoId is valid only once
        const urlWithoutSsoId = Utilities.removeURLParameter(window.location.href, Constants.SsoIdKey);
        window.history.replaceState(window.history.state, '', urlWithoutSsoId);

        return this.ssoService.get(ssoId).pipe(
            map((success) => {
                const result = {
                    status: Status.ok,
                    opId: 'local',
                    payload: {
                        sessionId: success.sessionId,
                        ticket: success.ticket,
                        userId: success.userId,
                        expiration: success.expiration
                    } as PayloadData
                } as OpResult;

                // Store the data under: parent-domain + ubisoft.com
                this.setTicket(success.ticket).subscribe(() => {});

                // Also store the login data under the current origin
                this.ls.setItem(Constants.loginDataKey, result, this.configService.config.localLoginExpirationTime);
                this.ls.setItem(Constants.SsoStatusKey, { id: RetrieveStatus.Stored }, 5);
                return result;
            }),
            catchError((error) => {
                this.ls.setItem(Constants.SsoStatusKey, { id: RetrieveStatus.NotFound }, 5);
                return throwError({
                    opId: 'local',
                    status: Status.ssoRetrieveApiError,
                    errorMessage: 'Could not obtain a session from the refresh flow.'
                } as OpResult);
            })
        );
    }

    public getUserStatus(ticket: string, sessionId: string, userId: string): Observable<OpResult> {
        const id = Communication.generateUniqueId(OpType.GetUserStatus);
        this.queue[id] = new Subject<OpResult>();
        this.communication.sendMessageToChild(
            {
                opId: id,
                type: OpType.GetUserStatus,
                thirdPartySupport: this.configService.config.thirdPartyCookieSupport,
                payload: { ticket: ticket, sessionId: sessionId, userId: userId }
            },
            this.configService.config.sender
        );
        return this.queue[id];
    }

    public getProfiles(ticket: string, sessionId: string, userId: string): Observable<OpResult> {
        const id = Communication.generateUniqueId(OpType.GetProfiles);
        this.queue[id] = new Subject<OpResult>();
        this.communication.sendMessageToChild(
            {
                opId: id,
                type: OpType.GetProfiles,
                thirdPartySupport: this.configService.config.thirdPartyCookieSupport,
                payload: { ticket: ticket, sessionId: sessionId, userId: userId }
            },
            this.configService.config.sender
        );
        return this.queue[id];
    }

    public getThirdPartyStatus(): Observable<OpResult> {
        const id = Communication.generateUniqueId(OpType.GetThirdPartyStatus);
        this.queue[id] = new Subject<OpResult>();
        this.communication.sendMessageToChild(
            {
                opId: id,
                type: OpType.GetThirdPartyStatus,
                thirdPartySupport: this.configService.config.thirdPartyCookieSupport
            },
            this.configService.config.sender
        );
        return this.queue[id];
    }

    public getUsername(ticket: string, sessionId: string, userId: string): Observable<OpResult> {
        const id = Communication.generateUniqueId(OpType.GetUsername);
        this.queue[id] = new Subject<OpResult>();
        this.communication.sendMessageToChild(
            {
                opId: id,
                type: OpType.GetUsername,
                thirdPartySupport: this.configService.config.thirdPartyCookieSupport,
                payload: { ticket: ticket, sessionId: sessionId, userId: userId }
            },
            this.configService.config.sender
        );
        return this.queue[id];
    }

    public setTicket(ticket: string): Observable<OpResult> {
        const id = Communication.generateUniqueId(OpType.SetTicket);
        this.queue[id] = new Subject<OpResult>();
        this.communication.sendMessageToChild(
            {
                opId: id,
                type: OpType.SetTicket,
                thirdPartySupport: this.configService.config.thirdPartyCookieSupport,
                payload: { ticket: ticket }
            },
            this.configService.config.sender
        );
        return this.queue[id];
    }

    public triggerEmailValidation(ticket: string, sessionId: string): Observable<OpResult> {
        const id = Communication.generateUniqueId(OpType.TriggerEmailActivation);
        this.queue[id] = new Subject<OpResult>();
        this.communication.sendMessageToChild(
            {
                opId: id,
                type: OpType.TriggerEmailActivation,
                thirdPartySupport: this.configService.config.thirdPartyCookieSupport,
                payload: { ticket: ticket, sessionId: sessionId }
            },
            this.configService.config.sender
        );
        return this.queue[id];
    }

    public getLSData(): Observable<OpResult> {
        const id = Communication.generateUniqueId(OpType.GetLSData);
        this.queue[id] = new Subject<OpResult>();
        this.communication.sendMessageToChild(
            {
                opId: id,
                type: OpType.GetLSData,
                thirdPartySupport: this.configService.config.thirdPartyCookieSupport
            },
            this.configService.config.sender
        );
        return this.queue[id];
    }

    private ticketRenewTimeout: number = -1;

    //This method will handle the automatic ticket renewal for the user
    private handleTicketRenewalProcess(sdkInit?: boolean) {
        //Don't run for the following retrieval statuses since there is no point but send sdk ready so the sdk can run
        const status = this.ls.getItem<any>(Constants.SsoStatusKey);
        if (
            status &&
            (status.id === RetrieveStatus.Fetch ||
                status.id === RetrieveStatus.LoggedOut ||
                status.id === RetrieveStatus.NotFound)
        ) {
            console.log(
                '[WebAuthSdk]: Status not valid for ticket renewal.' + (sdkInit ? 'Sending SDK Ready event...' : '')
            );
            if (sdkInit) {
                this.setSdkReadyStatus();
            }
            return;
        }

        if (this.ticketRenewTimeout != -1) {
            window.clearTimeout(this.ticketRenewTimeout);
            this.ticketRenewTimeout = -1;
        }

        //Get the existing ticket and check the mins before the expiration, WebAuth side (see Constants.maxMinLeftBeforeLoginDataRenewal)
        this.getTicket().subscribe((result: GetTicketOpResult) => {
            //If we cannot get the current ticket, don't start the process
            if (result.status !== Status.ok) {
                console.log(
                    '[WebAuthSdk]: Could not obtain ticket for auto renewal' +
                        (sdkInit ? 'Sending SDK Ready event...' : '')
                );
                if (sdkInit) {
                    this.setSdkReadyStatus();
                }
                return;
            }
            const minsBeforeExpiration = Utilities.getLoginDataMinsBeforeExpiration(result.payload);
            //Make sure to send sdk ready event if ticket is still valid
            if (sdkInit && minsBeforeExpiration > 0) {
                console.log('[WebAuthSdk]: Ticket is still valid. Sending SDK Ready event...');
                this.setSdkReadyStatus();
            }
            //Set a timeout to run right when the ticket will expire on WebAuth side
            console.log(
                '[WebAuthSdk]: Ticket obtained, starting the renewal in ' + minsBeforeExpiration + ' minutes...'
            );
            this.ticketRenewTimeout = window.setTimeout(
                () => {
                    this.extendExistingTicket();
                },
                minsBeforeExpiration * 60 * 1000
            );
        });
    }

    private extendExistingTicket(sdkInit?: boolean) {
        console.log('[WebAuthSdk]: Starting ticket renewal process...');
        //At this point, the ticket should be expired on WebAuth side, so calling getTicket should renew the existing session
        this.getTicket().subscribe((result: GetTicketOpResult) => {
            //If status is ok, setup the next renewal process and update the local loginData, if exists
            if (result.status === Status.ok) {
                console.log('[WebAuthSdk]: Ticket has been renewed, check and store local data...');
                if (this.ls.getItem<any>(Constants.loginDataKey)) {
                    this.ls.setItem(Constants.loginDataKey, result, this.configService.config.localLoginExpirationTime);
                }
                this.handleTicketRenewalProcess();
            } else {
                //If not ok, use logout method to clear the data
                console.log('[WebAuthSdk]: Ticket could not be renewed, removing current login data...');
                this.ls.removeItem(Constants.SsoStatusKey);
                this.ls.removeItem(Constants.loginDataKey);
                this.logoutWithThirdParties('', '').subscribe(() => {});
            }
            //Make sure to send sdk ready event after renewing the ticket at startup
            if (sdkInit) {
                this.setSdkReadyStatus();
            }
            else {
                //If not ok, use logout method to clear the data
                console.log("[WebAuthSdk]: Ticket could not be renewed, removing current login data...");
                this.ls.removeItem(Constants.SsoStatusKey);
                this.ls.removeItem(Constants.loginDataKey);
                this.logoutWithThirdParties("", "").subscribe(() => { });
            }
            //Make sure to send sdk ready event after renewing the ticket at startup
            if(sdkInit){
                this.setSdkReadyStatus();
            }
        });
    }
}

export function init(config: Config): void {
    if ((window as any).Connect.sdk === null || (window as any).Connect.sdk === undefined) {
        (window as any).Connect.sdk = new Subject<ConnectSdk>();
    }
    if ((window as any).Connect._sdk === undefined || (window as any).Connect._sdk === null) {
        const sdk = new ConnectSdk(
            config.env,
            config.appId,
            config.genomeId,
            config.lang,
            config.nextUrl,
            config.thirdPartyCookiesSupport,
            config.localLoginExpirationMinutes
        );
        sdk.ready.subscribe(() => {
            (window as any).Connect._sdk = sdk;
            (window as any).Connect.sdk.next((window as any).Connect._sdk);
        });
    } else {
        (window as any).Connect.sdk.next((window as any).Connect._sdk);
    }
}

type GetTicketOpResult = {
    opId: string;
    status: Status;
    payload: LoginData;
};

export interface Config {
    env: string;
    appId: string;
    genomeId?: string;
    lang?: string;
    nextUrl?: string;
    thirdPartyCookiesSupport?: boolean;
    localLoginExpirationMinutes?: number;
}

export interface ReadyStatus {
    sdkReady: boolean;
    thirdPartyCookiesEnabled: boolean;
    message: string;
    isPrimaryDomain: boolean;
}

export enum RetrieveStatus {
    Initial = 'InitialLoad',
    Fetch = 'TryFetch',
    NotFound = 'DataNotFound',
    Stored = 'DataStored',
    LoggedOut = 'LoggedOut'
}

export interface ThrottleState {
    count: number;
    throttledUntil: number;
}
