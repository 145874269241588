import { Singleton, Inject } from 'typescript-ioc/es5';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { ConfigService } from '../common/configService';
import { Constants } from '../common/constants';
import { LoginData } from '../private/loginData';
import { RememberMeData } from '../private/rememberMeData';
import { RememberDeviceData } from '../private/rememberDeviceData';

@Singleton
export class SsoService {
    @Inject
    private configService: ConfigService;
    private connectBaseUrl: string;
    private crossChannelAuthServiceBaseUrl: string;
    constructor() {
        this.connectBaseUrl = Constants.baseApiUrls.find((f) => f.key === this.configService.config.environment).value;
        this.crossChannelAuthServiceBaseUrl = Constants.crossChannelAuthServiceBaseUrls.find(
            (f) => f.key === this.configService.config.environment
        ).value;
    }

    public set = (ticket: string, sessionId?: string, userId?: string): Observable<SsoResponse> => {
        const headers: { [key: string]: any } = {
            'Content-Type': 'application/json',
            Authorization: 'ubi_v1 t=' + ticket,
            'Ubi-AppId': this.configService.config.appId,
            'Ubi-SessionId': sessionId
        };
        return ajax
            .post(this.crossChannelAuthServiceBaseUrl + '/public/sso/refresh', {}, headers)
            .pipe(map((s) => s.response as SsoResponse));
    };

    public setSso = (ticket: string): Observable<SsoResponse> => {
        const headers: { [key: string]: any } = {
            'Content-Type': 'application/json',
            Authorization: 'ubi_v1 t=' + ticket,
            'Ubi-AppId': this.configService.config.appId
        };
        return ajax
            .post(this.crossChannelAuthServiceBaseUrl + '/public/sso/entry', {}, headers)
            .pipe(map((s) => s.response as SsoResponse));
    };

    public get = (ssoId: string): Observable<SsoCompleteResponse> => {
        const headers: { [key: string]: any } = {
            'Content-Type': 'application/json',
            'Ubi-SsoId': ssoId,
            'Ubi-AppId': this.configService.config.appId
        };
        return ajax
            .get(this.crossChannelAuthServiceBaseUrl + '/public/sso/refresh', headers)
            .pipe(map((s) => s.response as SsoCompleteResponse));
    };

    public getSso = (ssoId: string): Observable<SsoCompleteResponse> => {
        const headers: { [key: string]: any } = {
            'Content-Type': 'application/json',
            'Ubi-SsoId': ssoId,
            'Ubi-AppId': this.configService.config.appId
        };
        return ajax
            .get(this.crossChannelAuthServiceBaseUrl + '/public/sso/entry', headers)
            .pipe(map((s) => s.response as SsoCompleteResponse));
    };

    public getAuth = (ssoId: string): Observable<SsoAuthDataResponse> => {
        const headers: { [key: string]: any } = {
            'Content-Type': 'application/json',
            'Ubi-SsoId': ssoId,
            'Ubi-AppId': this.configService.config.appId
        };

        return ajax
            .get(this.crossChannelAuthServiceBaseUrl + '/public/sso/token', headers)
            .pipe(map((s) => s.response as SsoAuthDataResponse));
    };

    public refreshAuth = ({
        sessionId,
        ubiTicket,
        rmTicket,
        rdTicket
    }: SsoAuthDataRequest): Observable<SsoAuthDataRefreshResponse> => {
        let headers: { [key: string]: any } = {
            'Ubi-AppId': this.configService.config.appId,
            WebAuthUrl: window.location.href,
            'Content-Type': 'application/json'
        };

        if (this.configService.config.genomeId) {
            headers['Ubi-GenomeId'] = this.configService.config.genomeId;
        }

        if (ubiTicket) {
            headers['Authorization'] = `ubi_v1 t=${ubiTicket}`;
        }

        if (sessionId) {
            headers['Ubi-SessionId'] = sessionId;
        }

        if (rmTicket) {
            headers['Ubi-RememberMeTicket'] = rmTicket;
        }

        if (rdTicket) {
            headers['Ubi-RememberDeviceTicket'] = rdTicket;
        }

        return ajax
            .post(this.crossChannelAuthServiceBaseUrl + '/public/sso/auth', {}, headers)
            .pipe(map((s) => s.response as SsoAuthDataRefreshResponse));
    };
}

export interface SsoResponse {
    ssoId: string;
}

export interface SsoCompleteResponse {
    ticket: string;
    sessionId: string;
    userId: string;
    expiration: string;
}

export interface SsoAuthDataRequest {
    sessionId?: string;
    ubiTicket?: string;
    rmTicket?: string;
    rdTicket?: string;
}

export interface SsoAuthDataResponse {
    ubiTicket?: string;
    rememberMeTicket?: string;
    rememberDeviceTicket?: string;
    emailAddress?: string;
    expiration?: string;
    sessionId?: string;
    userId?: string;
}

export type SsoAuthDataRefreshResponse = SsoAuthDataResponse & SsoResponse;
