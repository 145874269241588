import { Singleton, Inject } from 'typescript-ioc/es5';
import { Constants } from './constants';
import { ConfigService } from './configService';
import { ajax } from 'rxjs/ajax';
import { Observable } from 'rxjs';

@Singleton
export class DebugService {
    @Inject
    private configService: ConfigService;
    private connectWebAuthServiceBaseUrl: string;

    public constructor() {
        this.connectWebAuthServiceBaseUrl = Constants.webAuthServiceBaseUrls.find(
            (f) => f.key === this.configService.config.environment
        ).value;
    }

    public getDebugTransactionId = (action: string): string => {
        return '51ffc1a6-bfa8-42b6-8319-4bcc5f5616d' + action;
    };

    public sendDebugLogs = (ticket: string, caller: string, endpoint: string): Observable<any> => {
        const headers: { [key: string]: any } = {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        };
        const body: DebugLogEntryRequest = {
            appId: this.configService.config.appId,
            genomeId: this.configService.config.genomeId,
            ubiTicket: ticket,
            caller: caller,
            endpoint: endpoint,
            env: this.configService.config.environment,
            locale: this.configService.config.lang,
            isSdk: true
        };
        const debugLogsUrl = this.connectWebAuthServiceBaseUrl + '/public/monitor/nextdebuglog';

        return ajax.post(debugLogsUrl, body, headers);
    };
}

export interface DebugLogEntryRequest {
    appId: string;
    genomeId: string;
    ubiTicket: string;
    caller: string;
    endpoint: string;
    env: string;
    locale: string;
    isSdk: boolean;
}
